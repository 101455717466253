import BrandContactRequestDetailsComponentBrand from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentBrand';

import ContactRequestMediaUserDetailsComponent from '../../ConversationDetailView/DetailsComponents/ContactRequestMediaUserDetailsComponent';
import ExpertDetailsComponent from '../../ConversationDetailView/DetailsComponents/ExpertDetailsComponent';
import AdminIntervenableConversation from './AdminIntervenableConversation';

/**
 * Methd for determining detail text for a Brand Contact Request conversation
 * @param {*} contentObject contact request
 * @param {*} userIsRequester bool determining if the current chat user is the one requesting access or not
 * @returns string
 */
function determineHeaderDetailText(contentObject, userIsRequester) {
  // If media user show name of brand
  if (userIsRequester) {
    return `Expert Contact for ${contentObject.expert.name}`;
  }

  return `Subject: ${contentObject.expert.name}`;
}

/**
 * Class that implements custom header detail text and icon determination for Brand Contact Request conversations.
 */
export default class ExpertContactRequestConversation extends AdminIntervenableConversation {
  constructor(conversationObject) {
    super(conversationObject);

    const userIsRequester =
      this.getIdentity() &&
      this.getContentObject().requested_by.pk ===
        this.getIdentity().getUser().getPk();

    this.typeText = 'Contact Request';

    this.headerDetailText = determineHeaderDetailText(
      this.getContentObject(),
      userIsRequester
    );

    this.icon = this.getOtherMembersExcludingStaff()[0]
      .getUser()
      .getProfileImage();

    this.detailSections = userIsRequester
      ? {
          'Expert Details': ExpertDetailsComponent,
        }
      : {
          Details: BrandContactRequestDetailsComponentBrand,
          'Media Profile': ContactRequestMediaUserDetailsComponent,
        };

    // if user is requester, show profile url
    if (userIsRequester) {
      this.relatedLink = {
        title: 'View Expert Profile',
        href: `/experts/${this.getContentObject().expert.slug}/`,
      };
    }
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns string
   */
  getCustomFlavorTextForMember(member) {
    return this.getContentObject().requested_by.pk !== member.getUser().getPk()
      ? `Expert Contact for ${this.getContentObject().expert.name}`
      : 'Media Member';
  }

  /**
   * Returns the role text to be shown underneath a users name in the messaging area
   * @param {*} member
   * @returns string
   */
  getCustomRoleTextForMember(member) {
    return this.getContentObject().requested_by.pk !== member.getUser().getPk()
      ? `Contact for ${this.getContentObject().expert.name}`
      : '';
  }
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import utils from '../../../../presshook/foldering/static/foldering/js/collections/utils';
import StandaloneTagSelector from './jsx/tags/standaloneTagSelector';

// Sample Request Form modal javascript functions.
// The modal is present on the product detail page and the brand detail page,
// called via "Request Sample" button.

// Handles opening and closing the Sample Request form modal
export default function sampleRequestButtonComponent() {
  const modalButtons = document.getElementsByClassName('sample-request-target');
  const modal = document.getElementById('sampleRequestModal');
  // let activeModal = null;
  let alreadyBinded = false;

  Array.from(modalButtons).forEach((modalButton) => {
    modalButton.addEventListener('click', () => {
      // activeModal = modal;
      modal.style.display = 'block';
      document.querySelector('body').classList.add('body-sticky');
    });
    const closeButtons = modal.querySelectorAll('.close');
    Array.from(closeButtons).forEach((closeButton) => {
      closeButton.addEventListener('click', () => {
        modal.style.display = 'none';
        // activeModal = null;
        alreadyBinded = false;
        document.querySelector('body').classList.remove('body-sticky');
      });
    });
  });

  // This adds listeners to custom select input that is loaded
  // dynamically inside the Sample Request form modal
  const bindCustomSelectListeners = (bindingRoot) => {
    const theRoot = bindingRoot;
    theRoot.querySelectorAll('.custom-select').forEach((dropdown) => {
      dropdown.addEventListener('click', (e) => {
        let container = e.target.parentElement;
        if (container.classList.contains('custom-select__trigger')) {
          container = container.parentElement;
        }
        container.classList.toggle('open');
      });
    });

    function selectAnOption(option) {
      const target = option;
      const currentlySelected = target
        .closest('.custom-options')
        .querySelector('.custom-option.selected');
      if (currentlySelected) {
        currentlySelected.classList.remove('selected');
      }
      target.classList.add('selected');
      target
        .closest('.custom-select')
        .querySelector('.custom-select__trigger span').textContent =
        target.textContent;
    }

    theRoot.querySelectorAll('.custom-option').forEach((option) => {
      option.addEventListener('click', (e) => {
        if (!e.target.classList.contains('selected')) {
          selectAnOption(e.target);

          // Closing the option's container
          const container = e.target.parentElement.parentElement.parentElement;
          container.classList.toggle('open');
        }
      });

      // If one option is already selected when the DOM is loaded,
      // let's make sure it'll displayed as so
      const input = option.parentElement.querySelector('input');
      if (input.checked) {
        selectAnOption(option);
      }
    });

    window.addEventListener('click', (e) => {
      document.querySelectorAll('.custom-select').forEach((select) => {
        if (!select.contains(e.target)) {
          select.classList.remove('open');
        }
      });
    });
  };

  // This is observing #sampleRequestModal .inner to change
  // its content in order to bind the custom select listeners
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      const { target } = mutationRecord;
      if (!alreadyBinded && target.innerHTML.trim().length > 0) {
        alreadyBinded = true;

        bindCustomSelectListeners(target);

        // Increment counter if the select input at products image is selected
        const rootForm = document.getElementById('createSampleRequestForm');
        if (rootForm) {
          const selectItemInputs = rootForm.querySelectorAll(
            'input[name$=selected]'
          );
          selectItemInputs.forEach((item) => {
            item.addEventListener('change', (evt) => {
              const parent = item.closest('.counter-item');
              const quantityInput = parent.querySelector(
                '.counter-component input[name$=quantity]'
              );
              if (!evt.target.checked) {
                quantityInput.value = 0;
              } else if (quantityInput.value === '0') {
                quantityInput.value = 1;
              }
            });
          });

          // sets the currently URL as referrer url hidden param into the form
          const referrerEl = rootForm.querySelector('input[name=referrer_url]');
          if (referrerEl) referrerEl.value = window.location.href;

          // View more button handler to expand product list
          const viewMoreButton = rootForm.querySelector(
            '.counter-container__view-more'
          );
          if (viewMoreButton) {
            viewMoreButton.addEventListener('click', () => {
              const counterItems = rootForm.querySelectorAll('.counter-item');
              counterItems.forEach((item) => {
                item.classList.remove('counter-item--hidden');
              });
              viewMoreButton.style.display = 'none';
            });
          }

          // Outlets select render from React
          const outletsSelect = rootForm.querySelector(
            '#sampleRequestOutletsInjector'
          );
          if (outletsSelect) {
            const existingValue = outletsSelect.getAttribute(
              'data-existing-value'
            );
            const root = createRoot(outletsSelect);
            root.render(
              <StandaloneTagSelector
                tagType="media-outlet-tags"
                placeHolder="Enter or search outlets..."
                formInputName="samplerequest-outlets"
                containerStyle={{ width: '100%' }}
                withSuggestions
                existingValue={existingValue}
              />
            );
          }
        }
      }
    });
  });

  const sampleRequestModal = document.getElementById('sampleRequestModal');
  if (sampleRequestModal) {
    const sampleRequestModalInnerContainer =
      sampleRequestModal.querySelector('.modal-inner');
    if (sampleRequestModalInnerContainer) {
      observer.observe(sampleRequestModalInnerContainer, {
        attributes: true,
        childList: true,
        attributeOldValue: true,
      });
    }
  }

  // Change address info button handler
  window.addEventListener('click', (e) => {
    if (e.target && e.target.id === 'changeAddressInfoButton') {
      const container = document
        .getElementById('changeAddressInfoButton')
        .closest('.row.personal-info-row');
      const textDisplay = container.querySelector('.content-col .text-display');
      const formFields = container.querySelector('.content-col .form-fields');
      const displayAddressFields = textDisplay.style.display === 'none';
      if (!displayAddressFields) {
        textDisplay.style.display = 'none';
        formFields.style.display = 'block';
      } else {
        textDisplay.style.display = 'block';
        formFields.style.display = 'none';
      }
    }
  });

  // Change email button handler
  window.addEventListener('click', (e) => {
    if (e.target && e.target.id === 'changeEmailButton') {
      const container = document
        .getElementById('changeEmailButton')
        .closest('.row.personal-info-row');
      const textDisplay = container.querySelector('.content-col .text-display');
      const formFields = container.querySelector('.content-col .form-fields');
      const displayEmailField = textDisplay.style.display === 'none';
      if (!displayEmailField) {
        textDisplay.style.display = 'none';
        formFields.style.display = 'block';
      } else {
        textDisplay.style.display = 'block';
        formFields.style.display = 'none';
      }
    }
  });

  // Form submit clears alreadyBinded variable. This helps Request Reason
  // field to work after a failed attempt of submission
  window.addEventListener('submit', (e) => {
    if (e.target && e.target.id === 'createSampleRequestForm') {
      alreadyBinded = false;
    }
  });

  // Check if Product page since this script also runs on brands pages
  if (window.location.pathname.startsWith('/products/')) {
    const params = new URLSearchParams(window.location.search);
    if (params.has('requestSample')) {
      const requestSampleBtn = document.querySelector('.sample-request-btn');
      // The reason we are faking a click is to keep it simple, since the template already handles the logic
      // of opening the sign up modal, or the sample request modal or disabling it if the user already requested.
      if (requestSampleBtn)
        // We're waiting 1 second so the htmx lib is loaded. One more reason to stop using htmx...
        setTimeout(() => requestSampleBtn.click(), 1000);
    }
  }

  // Instead of swapping the form with a successful message,
  // closes the modal and display a toast.
  if (document.body) {
    document.body.addEventListener('htmx:beforeSwap', (event) => {
      if (event.detail.xhr.responseURL.endsWith('/success/')) {
        // prevents swap
        event.preventDefault();

        // closes the modal
        modal.style.display = 'none';
        alreadyBinded = false;
        document.querySelector('body').classList.remove('body-sticky');

        // Display successful message toast
        const { showMessage } = utils;
        const { status } = JSON.parse(event.detail.xhr.response);
        const message =
          status === 'on hold'
            ? 'Sample Request (on hold) created!'
            : 'Sample Request created!';
        showMessage(message);
      }
    });
  }
}
sampleRequestButtonComponent();

import React from 'react';

import { createRoot } from 'react-dom/client';
import StandaloneTagSelector from './jsx/tags/standaloneTagSelector';

function sourceRequestsFormInit() {
  const sourceRequestsForm =
    document.querySelector('#createSourceRequestForm') ||
    document.querySelector('#editSourceRequestForm');
  const dateTimePicker = sourceRequestsForm.querySelector(
    '.js-source-request-deadline'
  );
  if (dateTimePicker && !dateTimePicker.value) {
    // set current time min and 24 hours ahead as value for datetimepicker
    // picker expects ISO format minus the seconds, milliseconds and Z at the end
    let today = new Date();
    let tomorrow = new Date(today.getTime() + 86400000).toISOString(); // milliseconds in a day
    tomorrow = tomorrow.substring(0, tomorrow.length - 8);
    today = today.toISOString();
    today = today.substring(0, today.length - 8);
    dateTimePicker.value = tomorrow;
    dateTimePicker.min = today;
  }

  function updateTags(fullType, isChange = false) {
    const type = fullType.substring(0, fullType.length - 1);
    const tagsContainer = document.querySelector(`#id_${type}_tags`);
    const shownTags = document.querySelectorAll('.js-show-tags');
    const fakeTagInput = document.querySelector('.js-fake-tag-input');

    if (fakeTagInput) {
      fakeTagInput.remove();
    }
    if (tagsContainer) {
      if (isChange && shownTags[0]) {
        shownTags.forEach((element) => {
          element.classList.remove('js-show-tags');
        });
      }
      // This setTimeout is an workaround to make sure the field is shown
      // after a form_invalid on create, cause it would run before the field
      // was really rendered on those cases
      setTimeout(() => {
        tagsContainer.classList.add('js-show-tags');
      }, 500);
    }
  }

  // show correct type of tags based on request type
  const requestType = sourceRequestsForm.querySelector(
    '.js-source-request-type'
  );
  if (requestType) {
    requestType.addEventListener('change', (event) => {
      updateTags(event.target.value, true);
    });
    if (requestType.value) {
      updateTags(requestType.value);
    }
  }

  // disable button after submit the form
  const submitButton = sourceRequestsForm.querySelector('.submit-btn');
  if (submitButton) {
    sourceRequestsForm.addEventListener('submit', () => {
      submitButton.disabled = true;
    });
  }

  // Outlets select render from React
  const outletsSelect = sourceRequestsForm.querySelector(
    '#sourceRequestOutletsInjector'
  );
  if (outletsSelect) {
    const existingValue = outletsSelect.getAttribute('data-existing-value');
    const root = createRoot(outletsSelect);
    root.render(
      <StandaloneTagSelector
        tagType="media-outlet-tags"
        placeHolder="Enter or search outlets..."
        formInputName="media_outlets"
        containerStyle={{ width: '100%' }}
        withSuggestions
        existingValue={existingValue}
      />
    );
  }
}

// listen for htmx insertion of source request form
const observer = new MutationObserver((mutationsList) => {
  mutationsList.forEach((mutation) => {
    mutation.addedNodes.forEach((addedNode) => {
      if (
        addedNode.id === 'createSourceRequestForm' ||
        addedNode.id === 'editSourceRequestForm'
      ) {
        sourceRequestsFormInit();
      }
    });
  });
});

const createModal = document.querySelector('#createSourceRequestModal');
if (createModal) {
  observer.observe(createModal, {
    subtree: true,
    childList: true,
  });
}

const editModal = document.querySelector('#editSourceRequestModal');
if (editModal) {
  observer.observe(editModal, {
    subtree: true,
    childList: true,
  });
}

const searchBtn = document.querySelector('.js-search-btn');
const cancelBtn = document.querySelector('.js-cancel-btn');
const searchBox = document.querySelector('.js-search-box');

const textLogo = document.querySelector('.mobile-crop-company-logo');
const notificationIcons = document.querySelector(
  '.navigation__notifications-wrapper'
);

if (searchBtn) {
  searchBtn.onclick = () => {
    textLogo.classList.add('contract');
    searchBox.classList.add('active');
    if (notificationIcons)
      notificationIcons.classList.add(
        'navigation__notifications-wrapper--contract'
      );
    // textLogo.style = "display: none; height: 30px;"
    // iconLogo.style = "display: block; height: 30px;"
  };
}
if (cancelBtn) {
  cancelBtn.onclick = () => {
    textLogo.classList.remove('contract');
    searchBox.classList.remove('active');
    if (notificationIcons)
      notificationIcons.classList.remove(
        'navigation__notifications-wrapper--contract'
      );
    // textLogo.style = "display: block; height: 30px;"
    // iconLogo.style = "display: none; height: 30px;"
  };
}

// New Navigation Bar search box

const searchBtn_ = document.querySelector('.search__btn');
const cancelBtn_ = document.querySelector('.search__cancel-btn');
const searchBox_ = document.querySelector('.search__box');
const searchInput_ = document.querySelector('.search__input');

const textLogo_ = document.querySelector('.logo__img');

if (searchBtn_) {
  searchBtn_.onclick = () => {
    textLogo_.classList.add('logo__img--contract');
    if (notificationIcons)
      notificationIcons.classList.add(
        'navigation__notifications-wrapper--contract'
      );
    searchBox_.classList.add('search__box--active');
    searchInput_.classList.add('search__input--active');
    cancelBtn_.classList.add('search__cancel-btn--active');
    searchBox_.querySelector('input').focus();
  };
}
if (cancelBtn_) {
  cancelBtn_.onclick = () => {
    textLogo_.classList.remove('logo__img--contract');
    if (notificationIcons)
      notificationIcons.classList.remove(
        'navigation__notifications-wrapper--contract'
      );
    searchBox_.classList.remove('search__box--active');
    searchInput_.classList.remove('search__input--active');
    cancelBtn_.classList.remove('search__cancel-btn--active');
  };
}

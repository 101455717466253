/* Handles click on expert tags */
document.addEventListener('DOMContentLoaded', () => {
  const tags = document.querySelectorAll('.js-tag-click');

  tags.forEach((tag) => {
    tag.addEventListener('click', (e) => {
      e.preventDefault();

      location.href = tag.dataset.url;
    });
  });
});

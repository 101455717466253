import '../components/accountMenu';
import '../components/customSelect';
import '../components/createBrandModal';
import '../components/upgradeBrandModal';
import '../components/imgZoom';
import '../components/founderBios';
import '../components/brandDetailsView';
import '../components/brandMembershipTiers';
import '../components/brandMenu';
import '../components/banner';
import '../../../../presshook/foldering/static/foldering/js/collections/CommonComponents/CollectionsReactInjector';
import '../components/footer';
import '../components/mainNav';
import '../components/mobileNav';
import '../components/modals';
import '../components/newsFilter';
import '../components/productDetails';
import '../components/sideNavFilters';
import '../components/stickyNav';
import '../components/mobileSearch';
import '../components/drawer';
import '../components/pricingIntervalToggle';
import '../components/searchTracking';
import '../components/sideMenuCollapseHandler';
import '../components/loginSignupModal';
import '../components/shareModal';
import '../components/checkboxSelectMultiple';
import '../components/slider'; // abstract behavior for sliders
import '../components/tag'; // abstract behavior for tag clicks
import '../components/autoCompress'; // utility to strip out items til a set height is met, for tags/cert
import '../components/expertCardBio'; // set bio height for expert cards
import '../components/tooltip'; // more complex js based tooltip
import '../components/ph-tooltip'; // more simple css based tooltip
import '../components/sourceRequestsForm'; // source requests form custom js
import '../../../../presshook/votes/static/votes/js/votingInjector'; // injector of voting component
import '../components/collapsable'; // component for collapsable elements
import '../components/sampleRequestForm'; // sample request form modal
import '../components/schedule_a_call'; // Dismiss schedule a call banner

// add brand and add expert modals injector
import '../../../../presshook/brands/static/brands/js/components/addBrand/addBrandModalInjector';
import '../../../../presshook/experts/static/experts/js/components/addExpert/addExpertModalInjector';

// demo and contact request popups
import '../components/intercom';
import '../components/demoModal';

// React Components
// import './components/jsx/reactExample';
import '../components/navInboxInjector'; // JSX inbox injector
import '../components/jsx/elastic_search/search';
import '../components/jsx/notifications/Notifications';
import '../components/tagSelectorInjector';
import '../../../../presshook/core/static/core/js/components/profile_selector/profileSelectorInjector';
import '../../../../presshook/core/static/core/js/components/user_menu/userMenuInjector'; // user menu injector
import '../../../../presshook/core/static/core/js/components/organization_selector/organizationSelectorInjector';

// Animation globals for react
// Handles obeying accessibility settings for reduced motion (on platforms that support it)
import '../components/jsx/utils/animationReducer';

// AI Headlines
import '../components/aiHeadlines';

// Subscriptions
// eslint-disable-next-line max-len
import '../../../../presshook/subscriptions/static/subscriptions/js/components/upgradeSubscriptionModal/UpgradeModalInjector';

// Toastify defaults changes
import Toastify from 'toastify-js';

// Generic trackables
import '../utils/genericTrackable';

// load this last since forms might be built with JS
import '../components/autoSubmitForm';

// load stripe
import '@stripe/stripe-js';

Toastify.defaults.style.background = '#d2281a';
Toastify.defaults.close = true;

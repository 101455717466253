import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Radio,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Slide,
  Link,
} from '@mui/material';

import fetchData from '../../../../../../../config/static/js/utils/fetchData';
import useLocalStorage from '../../../../../../../config/static/js/utils/useLocalStorage';
import deepEqual from '../../../../../../../config/static/js/utils/deepEqual';
import AddBrandModal from '../../../../../../../presshook/brands/static/brands/js/components/addBrand/addBrandModal';
import AddExpertModal from '../../../../../../../presshook/experts/static/experts/js/components/addExpert/addExpertModal';
import UpgradeSubscriptionModal from '../../../../../../../presshook/subscriptions/static/subscriptions/js/components/upgradeSubscriptionModal/UpgradeSubscriptionModal';
import logError from '../../../../../../../config/static/js/utils/logError';

/**
 * A component that allows the user to select a profile from a list of brands and experts.
 * @returns {JSX.Element} The ProfileSelection component.
 */
export default function ProfileSelection({ hasSampleRequests, userOrgRole }) {
  const [error, setError] = useState(null);
  const [brands, setBrands] = useState([]);
  const [experts, setExperts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabDirection, setTabDirection] = useState('left');
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('brands');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState('');
  const [selectedProfile, setSelectedProfile] = useLocalStorage(
    'selectedProfile',
    {}
  );
  const [addBrandOpen, setAddBrandOpen] = useState(false);
  const [addExpertOpen, setAddExpertOpen] = useState(false);

  useEffect(() => {
    // Fetch brands and experts from API
    const getData = async () => {
      setIsLoading(true);
      try {
        const [brandsResponse, expertsResponse] = await Promise.all([
          fetchData(`/api/brands/current-user/?time=${new Date().getTime()}`),
          fetchData(`/api/experts/current-user/?time=${new Date().getTime()}`),
        ]);
        handleProfiles([...brandsResponse, ...expertsResponse]);
      } catch (error) {
        logError(error);
        setError('An unexpected error occurred. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleProfiles = (data) => {
    const brands = data.filter((profile) => profile.class_name === 'Brand');
    const experts = data.filter((profile) => profile.class_name === 'Bio');
    setBrands(brands);
    setExperts(experts);

    // If a profile is already selected, update it if necessary
    if (selectedProfile && Object.keys(selectedProfile).length > 0) {
      const selectedProfileIndex = data.findIndex(
        (profile) =>
          profile.pk === selectedProfile.pk &&
          profile.class_name === selectedProfile.class_name
      );
      if (
        selectedProfileIndex > -1 &&
        !deepEqual(data[selectedProfileIndex], selectedProfile)
      ) {
        setSelectedProfile(data[selectedProfileIndex]);
      }
      return;
    }
    // If no profile is selected, set the first brand or expert as the selected profile
    setSelectedProfile(brands[0] || experts[0]);
  };

  useEffect(() => {
    // log error
    if (error) {
      logError(error);
    }
  }, [error]);

  const openUpgradeModal = (event, feature) => {
    event.preventDefault();
    setSelectedFeature(feature);
    setShowUpgradeModal(true);
  };

  const openFlaggedUrl = (event, hasFeature, feature, url) => {
    event.preventDefault();
    handleCloseMenu();
    if (hasFeature) {
      // Open the URL
      window.open(url);
    } else {
      // Open the upgrade modal
      openUpgradeModal(event, feature);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Handle click for expand icon (to toggle the accordion)
  const handleAccordionClick = () => {
    setAccordionOpen(!accordionOpen);
  };

  // Handle add brand button click
  const handleAddBrandClick = () => {
    setAddBrandOpen(true);
    handleCloseMenu();
  };

  // Handle add expert button click
  const handleAddExpertClick = () => {
    setAddExpertOpen(true);
    handleCloseMenu();
  };

  const handleTabChange = (event, newValue) => {
    setTabDirection(currentTab < newValue ? 'left' : 'right');
    setCurrentTab(newValue);
  };

  const handleProfileChange = (profile) => {
    setSelectedProfile(profile);
  };

  const defaultProfileImage = (type) => {
    if (type == 'Bio') {
      return `${staticUrl}/images/svg/user.svg`;
    } else {
      return `${staticUrl}/images/svg/quote-placeholder.svg`;
    }
  };

  const setDefaultProfileImage = (event, type) => {
    event.target.src = defaultProfileImage(type);
  };

  return (
    <>
      <div className="profile-selection">
        <IconButton
          className={`profile-selection__navbar-icon ${
            selectedProfile && !(brands.length === 0 || experts.length === 0)
              ? 'profile-selection__navbar-icon--selected'
              : ''
          }`}
          onClick={handleMenuClick}
        >
          {!isLoading &&
          ((brands.length === 0 && experts.length === 0) ||
            !selectedProfile) ? (
            <img
              src={`${staticUrl}/images/svg/add.svg`}
              alt="Add Profile"
              className="profile-selection__navbar-icon-plus"
            />
          ) : (
            <div
              className={`profile-selection__icon-wrapper ${
                isLoading ? 'profile-selection__icon-wrapper--loading' : ''
              }`}
            >
              <img
                src={
                  selectedProfile?.logo?.url ||
                  defaultProfileImage(selectedProfile?.class)
                }
                alt={
                  selectedProfile?.title || selectedProfile?.name || 'Profile'
                }
                onError={(event) =>
                  setDefaultProfileImage(event, selectedProfile?.class)
                }
                data-testid="profile-selection-icon"
              />
            </div>
          )}
        </IconButton>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          classes={{
            paper: 'profile-selection__menu',
          }}
        >
          <div>
            {brands.length === 0 && experts.length === 0 ? (
              <div className="profile-selection__menu-lower profile-selection__menu-add-only">
                <MenuItem
                  className="profile-selection__menu-item"
                  onClick={() => {
                    setAddBrandOpen(true);
                  }}
                >
                  <img
                    src={`${staticUrl}/images/svg/plus-icon.svg`}
                    alt="Add Brand"
                  />
                  <div>Add Brand</div>
                </MenuItem>
                <MenuItem
                  className="profile-selection__menu-item"
                  onClick={() => {
                    setAddExpertOpen(true);
                  }}
                >
                  <img
                    src={`${staticUrl}/images/svg/plus-icon.svg`}
                    alt="Add Brand"
                  />
                  <div>Add Expert</div>
                </MenuItem>
              </div>
            ) : (
              <>
                <div className="profile-selection__menu-upper">
                  <div className="profile-selection__current">
                    <div
                      className={`profile-selection__icon-container ${
                        selectedProfile
                          ? 'profile-selection__icon-container--selected'
                          : ''
                      }`}
                    >
                      <img
                        className="profile-selection__icon"
                        src={
                          selectedProfile?.logo?.url ||
                          defaultProfileImage(selectedProfile?.class)
                        }
                        alt={selectedProfile?.title || selectedProfile?.name}
                        onError={(event) =>
                          setDefaultProfileImage(event, selectedProfile?.class)
                        }
                      />
                    </div>
                    <div className="profile-selection__name">
                      {selectedProfile?.title ||
                        selectedProfile?.name ||
                        'Select a profile'}
                    </div>
                  </div>
                  <IconButton
                    className="profile-selection__expand-icon"
                    onClick={handleAccordionClick}
                    data-testid="profile-selection-expand-icon"
                  >
                    <img
                      src={`${staticUrl}/images/svg/profile-switch.svg`}
                      alt="Switch Profile"
                    />
                  </IconButton>
                </div>
                <div className="profile-selection__menu-lower">
                  <Collapse
                    in={accordionOpen}
                    timeout="auto"
                    unmountOnExit
                    className="profile-selection__menu-options"
                  >
                    <div className="profile-selection__menu-header">
                      Switch Profile:
                    </div>
                    {/* Tabs */}
                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      className="profile-selection__tabs"
                    >
                      <Tab label="BRANDS" value="brands" />
                      <Tab label="EXPERTS" value="experts" />
                    </Tabs>
                    {/* Tab Contents */}

                    <Slide
                      direction={tabDirection}
                      in={currentTab === 'brands'}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="profile-selection__options-list">
                        {/* Brands Tab Content */}
                        {currentTab === 'brands' &&
                          brands.map((brand, key) => (
                            <MenuItem
                              key={key}
                              onClick={() => handleProfileChange(brand)}
                              className="profile-selection__options-item"
                            >
                              <img
                                className="profile-selection__options-icon"
                                src={
                                  brand.logo?.url ||
                                  defaultProfileImage('Brand')
                                }
                                onError={(event) =>
                                  setDefaultProfileImage(event, 'Brand')
                                }
                                alt={brand.title}
                              />
                              <div className="profile-selection__options-text">
                                {brand.title}
                              </div>
                              <div className="flex-fill"></div>
                              <Radio
                                checked={selectedProfile?.pk === brand.pk}
                              />
                            </MenuItem>
                          ))}
                        {currentTab === 'brands' && userOrgRole !== 'member' && (
                          <MenuItem
                            className="profile-selection__add-button"
                            onClick={() => handleAddBrandClick()}
                          >
                            <img
                              src={`${staticUrl}/images/svg/plus-icon.svg`}
                              alt="Add Brand"
                            />
                            <div>Add a Brand</div>
                          </MenuItem>
                        )}
                      </div>
                    </Slide>
                    <Slide
                      direction={tabDirection}
                      in={currentTab === 'experts'}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className="profile-selection__options-list">
                        {/* Experts Tab Content */}
                        {currentTab === 'experts' &&
                          experts.map((expert, key) => (
                            <MenuItem
                              key={key}
                              onClick={() => handleProfileChange(expert)}
                              className="profile-selection__options-item"
                            >
                              <img
                                className="profile-selection__options-icon"
                                src={expert?.icon || defaultProfileImage('Bio')}
                                alt={expert.name}
                                onError={(event) =>
                                  setDefaultProfileImage(event, 'Bio')
                                }
                              />
                              <div className="profile-selection__options-text">
                                {expert.name}
                              </div>
                              <div className="flex-fill"></div>
                              <Radio
                                checked={selectedProfile?.pk === expert.pk}
                              />
                            </MenuItem>
                          ))}
                        {currentTab === 'experts' && userOrgRole !== 'member' && (
                          <MenuItem
                            className="profile-selection__add-button"
                            onClick={() => handleAddExpertClick()}
                          >
                            <img
                              src={`${staticUrl}/images/svg/plus-icon.svg`}
                              alt="Add"
                            />
                            <div>Add an Expert</div>
                          </MenuItem>
                        )}
                      </div>
                    </Slide>
                  </Collapse>

                  {/* Profile Links */}
                  <div className="profile-selection__profile-links">
                    {selectedProfile && (
                      <MenuItem
                        className="profile-selection__menu-item"
                        component={Link}
                        href={
                          selectedProfile?.class_name == 'Brand'
                            ? `/portal/brands/edit/${selectedProfile?.pk}`
                            : `/experts/profile/edit?expert_pk=${selectedProfile?.pk}`
                        }
                      >
                        <img
                          src={`${staticUrl}/images/svg/menu-edit.svg`}
                          className="profile-selection__menu-item-icon"
                        />
                        {selectedProfile?.class_name == 'Bio' ? (
                          <div>Expert Editor</div>
                        ) : (
                          <div>Brand Editor</div>
                        )}
                        <div className="flex-fill"></div>
                        <div className="profile-selection__menu-item-notification">
                          {selectedProfile?.class_name == 'Brand' &&
                            !selectedProfile?.live && (
                              <div className="profile-selection__menu-item-notification-pill profile-selection__menu-item-notification-pill--grey">
                                draft
                              </div>
                            )}
                        </div>
                      </MenuItem>
                    )}
                    {selectedProfile && selectedProfile?.live && (
                      <MenuItem
                        className="profile-selection__menu-item"
                        component={Link}
                        href={
                          selectedProfile?.class_name == 'Brand'
                            ? `/brands/${selectedProfile?.slug}`
                            : `/experts/${selectedProfile?.slug}`
                        }
                      >
                        <img
                          src={`${staticUrl}/images/svg/menu-eye.svg`}
                          className="profile-selection__menu-item-icon"
                        />
                        <div>Public Profile</div>
                        <div className="flex-fill"></div>
                        <div className="profile-selection__menu-item-notification"></div>
                      </MenuItem>
                    )}
                    {selectedProfile &&
                      selectedProfile.class_name === 'Brand' &&
                      selectedProfile.live && (
                        <MenuItem
                          className="profile-selection__menu-item"
                          component={Link}
                          onClick={(event) =>
                            openFlaggedUrl(
                              event,
                              hasSampleRequests,
                              'sample-requests',
                              '/sample-requests/'
                            )
                          }
                        >
                          <img
                            src={`${staticUrl}/images/svg/menu-sample-requests.svg`}
                            className="profile-selection__menu-item-icon"
                          />
                          <div>Sample Requests</div>
                          <div className="flex-fill"></div>
                          <div className="profile-selection__menu-item-notification">
                            {!hasSampleRequests ? (
                              <img
                                src={`${staticUrl}/images/svg/upgrade.svg`}
                                alt="Upgrade"
                              />
                            ) : (
                              selectedProfile?.sample_request_count > 0 && (
                                <div className="profile-selection__menu-item-notification-number">
                                  {selectedProfile.sample_request_count}
                                </div>
                              )
                            )}
                          </div>
                        </MenuItem>
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Menu>
      </div>
      <AddBrandModal
        isOpenExternal={addBrandOpen}
        setIsOpenExternal={setAddBrandOpen}
      />
      <AddExpertModal
        isOpenExternal={addExpertOpen}
        setIsOpenExternal={setAddExpertOpen}
      />
      {showUpgradeModal && (
        <UpgradeSubscriptionModal
          selectedFeatureExternal={selectedFeature}
          objectTypeExternal={selectedProfile?.class_name.toLowerCase()}
          objectIdExternal={selectedProfile?.pk}
          setIsOpenExternal={setShowUpgradeModal}
          isOpenExternal={showUpgradeModal}
        />
      )}
    </>
  );
}

/**
 * Heavily based on https://github.com/zoltantothcom/vanilla-js-tooltip
 */
const delay = 0;
const dist = 10;
const className = 'presshook-tooltip';

/**
 * Return positions for the tooltip.
 *
 * @param {object} parent - The trigger of the tooltip.
 * @param {object} tooltip - The tooltip itself.
 * @param {string} posHorizontal - Desired horizontal position of the tooltip
 *                                 relatively to the trigger (left/left-align/center/right)
 * @param {string} posVertical - Desired vertical position of the tooltip relatively to the trigger (top/center/bottom)
 *
 * @return {Array} [leftPostion, topPosition]
 */
function getTooltipPositions(parent, tooltip, posHorizontal, posVertical) {
  const parentCoords = parent.getBoundingClientRect();
  let left;
  let top;

  switch (posHorizontal) {
    case 'left':
      left = parseInt(parentCoords.left, 10) - dist - tooltip.offsetWidth;
      if (parseInt(parentCoords.left, 10) - tooltip.offsetWidth < 0) {
        left = dist;
      }
      break;

    // Special case for navbar disabled item tooltip alignment
    case 'left-align':
      left = parseInt(parentCoords.left, 10);
      if (parseInt(parentCoords.left, 10) - tooltip.offsetWidth < 0) {
        left = dist;
      }
      break;

    case 'right':
      left = parentCoords.right + dist;
      if (
        parseInt(parentCoords.right, 10) + tooltip.offsetWidth >
        document.documentElement.clientWidth
      ) {
        left =
          document.documentElement.clientWidth - tooltip.offsetWidth - dist;
      }
      break;

    case 'center':
    default:
      left =
        parseInt(parentCoords.left, 10) +
        (parent.offsetWidth - tooltip.offsetWidth) / 2;
  }

  switch (posVertical) {
    case 'center':
      top =
        (parseInt(parentCoords.top, 10) + parseInt(parentCoords.bottom, 10)) /
          2 -
        tooltip.offsetHeight / 2;
      break;

    case 'bottom':
      top = parseInt(parentCoords.bottom, 10) + dist;
      break;

    case 'top':
    default:
      top = parseInt(parentCoords.top, 10) - tooltip.offsetHeight - dist;
  }

  left = left < 0 ? parseInt(parentCoords.left, 10) : left;
  top = top < 0 ? parseInt(parentCoords.bottom, 10) + dist : top;

  return [`${left}px`, `${top + scrollY}px`];
}

/*
 * Attaching one mouseover and one mouseout listener to the document
 * instead of listeners for each trigger
 */
document.body.addEventListener('mouseover', (e) => {
  if (!e.target.hasAttribute('data-tooltip')) return;

  const tooltip = document.createElement('div');
  tooltip.className = className;
  tooltip.innerHTML = e.target.getAttribute('data-tooltip');

  document.body.appendChild(tooltip);

  const pos = e.target.getAttribute('data-position') || 'center top';
  const posHorizontal = pos.split(' ')[0];
  const posVertical = pos.split(' ')[1];

  const [leftPostion, topPosition] = getTooltipPositions(
    e.target,
    tooltip,
    posHorizontal,
    posVertical
  );

  tooltip.style.left = leftPostion;
  tooltip.style.top = topPosition;
});

document.body.addEventListener('mouseout', (e) => {
  if (e.target.hasAttribute('data-tooltip')) {
    setTimeout(() => {
      document.body.removeChild(document.querySelector(`.${className}`));
    }, delay);
  }
});

import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering brand contact request details for a Brand
 */
function BrandContactRequestDetailsComponentBrand({ conversation }) {
  /*
  Date comes down as YYYY-MM-DD
  Chrome will parse as UTC, Firefox parses local.
  Add T12:00:00Z to force it to be recognized as noon UTC.
  */
  const deadlineDate = conversation.getContentObject().deadline_date
    ? new Date(`${conversation.getContentObject().deadline_date}T12:00:00Z`)
    : null;

  // Neutralize time zone adjustment when converted to string, since the original date doesn't come with one
  deadlineDate?.setTime(
    deadlineDate?.getTime() + deadlineDate?.getTimezoneOffset() * 60 * 1000
  );

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">Subject:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-subject"
      >
        {conversation.getContentObject().subject}
      </span>
      {deadlineDate !== null && <hr />}
      {deadlineDate !== null && (
        <h3 className="conversation-detail-view__details-heading">Deadline:</h3>
      )}
      {deadlineDate !== null && (
        <span
          className="conversation-detail-view__details-text"
          data-testid="brand-contact-deadline"
        >
          {deadlineDate.toLocaleDateString()}
        </span>
      )}
    </div>
  );
}

BrandContactRequestDetailsComponentBrand.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default BrandContactRequestDetailsComponentBrand;

import Conversation from '../DataTypes/Conversation';
import SampleRequestConversation from '../ConversationTypes/SampleRequestConversation';
import BrandContactRequestConversation from '../ConversationTypes/BrandContactRequestConversation';
import ExpertContactRequestConversation from '../ConversationTypes/ExpertContactRequestConversation';
import MediaContactRequestConversation from '../ConversationTypes/MediaContactRequestConversation';
import SourceRequestConversation from '../ConversationTypes/SourceRequestConversation';

/**
 * Helper method to automatically create conversation of correct type.
 * @param {*} conversationObject conversation to create object of
 * @returns ConversationObject or child class type
 */
export default function createConversation(
  conversationObject,
  getDetails = true
) {
  let model = null;

  if (conversationObject.content_type) {
    model = conversationObject.content_type.model || null;
  }

  if (model === 'samplerequest' && getDetails) {
    return new SampleRequestConversation(conversationObject);
  }

  if (model === 'brandcontactrequest' && getDetails) {
    return new BrandContactRequestConversation(conversationObject);
  }

  if (model === 'expertcontactrequest' && getDetails) {
    return new ExpertContactRequestConversation(conversationObject);
  }

  if (model === 'mediacontactrequest' && getDetails) {
    return new MediaContactRequestConversation(conversationObject);
  }

  if (model === 'pitch' && getDetails) {
    return new SourceRequestConversation(conversationObject);
  }

  return new Conversation(conversationObject);
}

import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Link } from '@mui/material';
import PropTypes from 'prop-types';

import UpgradeSubscriptionModal from '../../../../../../subscriptions/static/subscriptions/js/components/upgradeSubscriptionModal/UpgradeSubscriptionModal';

import asyncFetch from '../../../../../../../config/static/js/components/jsx/utils/asyncFetch';
import useLocalStorage from '../../../../../../../config/static/js/utils/useLocalStorage';
import logError from '../../../../../../../config/static/js/utils/logError';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

/**
 * UserMenu component displays a menu for the user to access their account information and links.
 * @param {Object} user - The user object containing user information.
 * @param {string} user.firstName - The first name of the user.
 * @param {string} user.lastName - The last name of the user.
 * @param {string} user.title - The title of the user.
 * @param {string} user.name - The name of the user.
 * @param {string} user.profileImageUrl - The URL of the user's profile image.
 * @param {boolean} user.isBrand - Whether the user is a brand.
 * @param {boolean} user.isExpert - Whether the user is an expert.
 * @param {boolean} user.isMedia - Whether the user is media.
 * @param {boolean} user.isStaff - Whether the user is staff.
 * @param {boolean} user.isApproved - Whether the user is approved.
 * @param {string} user.pathwrightId - The Pathwright ID of the user.
 * @param {string} user.email - The email of the user.
 * @param {string} user.hasPr101 - Whether the user has PR 101.
 * @param {string} user.hasPrToolbox - Whether the user has PR Toolbox.
 * @param {string} user.hasMultipleUsers - Whether the user has multiple users
 * @param {Array} user.shopifyUrl - The user's Shopify URLs.
 * @param {Object} featureFlags - The feature flags object.
 * @param {boolean} featureFlags.flagSubscriptionManagement - Whether to show the subscription management link.
 * @returns {JSX.Element} - The UserMenu component.
 */
export default function UserMenu({ user, featureFlags }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useLocalStorage('organization', {});
  const [myShopifyUrl, setMyShopifyUrl] = useLocalStorage('myShopifyUrl', []);
  const [error, setError] = useState(null);
  const [isOrganization, setIsOrganization] = useState(false);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await asyncFetch(
          '/api/organizations/current-user/',
          handleOrganization,
          setError
        );
      } catch (error) {
        logError(error);
        setError('An unexpected error occurred. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    // set shopifyUrl to localstorage.
    if (user?.shopifyUrl) {
      setMyShopifyUrl(user.shopifyUrl);
    }

    fetchData();
  }, [user]);

  const handleLogout = async () => {
    const options = {
      method: 'POST',
      credentials: 'include', // To ensure cookies are sent with the request
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    };

    try {
      const response = await fetch('/accounts/logout/', options);
      if (response.ok) {
        // Clear organization from local storage
        setOrganization({});
        localStorage.removeItem("selectedProfile");
        // Redirect to login page or any other page
        window.location.href = '/accounts/login/';
      } else {
        // Handle errors, such as showing an error message to the user
        alert('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Logout error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]); // Dependency on error to ensure this only runs when error changes

  const openUpgradeModal = (event, feature) => {
    event.preventDefault();
    setSelectedFeature(feature);
    setShowUpgradeModal(true);
  };

  const openFlaggedUrl = (event, hasFeature, feature, url) => {
    event.preventDefault();
    handleCloseMenu();
    if (hasFeature) {
      // Open the URL
      window.location.href = url;
    } else {
      // Open the upgrade modal
      openUpgradeModal(event, feature);
    }
  };

  const handleOrganization = (data) => {
    setOrganization(data);
    setIsOrganization(data.user_count > 1);
    setIsOrgAdmin(data.has_admin_access);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const defaultProfileImage = (type = null) => {
    if (!type == 'user' || isOrganization) {
      return `${staticUrl}/images/svg/my-brands-icon.svg`;
    }
    return `${staticUrl}/images/svg/user.svg`;
  };

  const setDefaultProfileImage = (event, type = null) => {
    event.target.src = defaultProfileImage(type);
  };

  return (
    <>
      <div className="profile-selection">
        <IconButton
          onClick={handleMenuClick}
          className={
            user?.isBrand || user?.isExpert
              ? `profile-selection__navbar-icon user-menu__user-icon-container`
              : ``
          }
          data-testid="user-menu-icon"
        >
          {user?.isBrand || user?.isExpert ? (
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`user-menu__icon ${
                isLoading ? 'user-menu__icon--loading' : ''
              }`}
            >
              <g id="charm:menu-kebab">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M13.625 6.625C14.7296 6.625 15.625 5.72957 15.625 4.625C15.625 3.52043 14.7296 2.625 13.625 2.625C12.5204 2.625 11.625 3.52043 11.625 4.625C11.625 5.72957 12.5204 6.625 13.625 6.625Z"
                    fill="#262121"
                  />
                  <path
                    id="Vector_2"
                    d="M13.625 14.875C14.7296 14.875 15.625 13.9796 15.625 12.875C15.625 11.7704 14.7296 10.875 13.625 10.875C12.5204 10.875 11.625 11.7704 11.625 12.875C11.625 13.9796 12.5204 14.875 13.625 14.875Z"
                    fill="#262121"
                  />
                  <path
                    id="Vector_3"
                    d="M13.625 23.125C14.7296 23.125 15.625 22.2296 15.625 21.125C15.625 20.0204 14.7296 19.125 13.625 19.125C12.5204 19.125 11.625 20.0204 11.625 21.125C11.625 22.2296 12.5204 23.125 13.625 23.125Z"
                    fill="#262121"
                  />
                </g>
              </g>
            </svg>
          ) : (
            <div
              className={`profile-selection__icon-wrapper user-menu__user-icon-wrapper ${
                isLoading
                  ? 'profile-selection__icon-wrapper--loading user-menu__user-icon-wrapper--loading'
                  : ''
              }`}
            >
              <img
                src={user?.profileImageUrl || defaultProfileImage()}
                alt={user?.first_name || 'Profile'}
                onError={(event) => setDefaultProfileImage(event)}
              />
            </div>
          )}
        </IconButton>

        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          classes={{
            paper: 'profile-selection__menu',
          }}
        >
          <div>
            <div className="profile-selection__menu-upper">
              <div className="profile-selection__current">
                <div className="profile-selection__icon-container user-menu__user-icon-container">
                  <img
                    className="profile-selection__icon"
                    src={
                      (isOrganization
                        ? organization.logo
                        : user?.profileImageUrl) || defaultProfileImage()
                    }
                    alt={isOrganization ? organization.name : user?.name}
                    onError={(event) => setDefaultProfileImage(event)}
                  />
                </div>
                <div className="user-menu__upper-text">
                  <div className="user-menu__upper-text--title">
                    My{' '}
                    {isOrganization
                      ? user.is_agency
                        ? 'Agency'
                        : 'Organization'
                      : 'Account'}
                  </div>
                  <div className="profile-selection__name">
                    {isOrganization
                      ? organization?.name
                      : user?.firstName + ' ' + user?.lastName}
                  </div>
                </div>
              </div>
              {/* @TODO: Add edit profile link for orgs when ready */}
              {!isOrganization && (
                <IconButton
                  className="profile-selection__expand-icon"
                  component={Link}
                  href="/accounts/profile/personal/"
                >
                  <img
                    src={`${staticUrl}/images/svg/edit.svg`}
                    alt="Edit Profile"
                  />
                </IconButton>
              )}
            </div>
            <div className="profile-selection__menu-lower">
              {/* Profile Links */}
              <div className="profile-selection__profile-links">
                {((user.isApproved && user?.isMedia) || !user?.isStaff) && (
                  <>
                    {isOrganization && (
                      <>
                        <MenuItem
                          className="profile-selection__menu-item"
                          component={Link}
                          href="/accounts/profile/personal/"
                        >
                          <div className="profile-selection__icon-container user-menu__user-icon-container">
                            <img
                              className="profile-selection__icon"
                              src={
                                user?.profileImageUrl ||
                                defaultProfileImage('user')
                              }
                              alt={user?.title || user?.name}
                              onError={(event) =>
                                setDefaultProfileImage(event, 'user')
                              }
                            />
                          </div>
                          <div className='profile-selection__full-name'>
                            {user?.firstName} {user?.lastName}
                          </div>
                          <div className="flex-fill"></div>
                          <div className="profile-selection__menu-item-notification">
                            <img
                              src={`${staticUrl}/images/svg/edit.svg`}
                              alt="Edit Profile"
                            />
                          </div>
                        </MenuItem>
                        <hr className="profile-selection__menu-divider" />
                      </>
                    )}
                    <MenuItem
                      className="profile-selection__menu-item"
                      component={Link}
                      href="/source-requests"
                    >
                      <img
                        src={`${staticUrl}/images/svg/menu-source-requests.svg`}
                        className="profile-selection__menu-item-icon"
                      />
                      <div>My Source Requests</div>
                      <div className="profile-selection__menu-item-notification"></div>
                    </MenuItem>
                    <MenuItem
                      className="profile-selection__menu-item"
                      component={Link}
                      href="/sample-requests"
                    >
                      <img
                        src={`${staticUrl}/images/svg/menu-sample-requests.svg`}
                        className="profile-selection__menu-item-icon"
                      />
                      <div>My Sample Requests</div>
                      <div className="profile-selection__menu-item-notification"></div>
                    </MenuItem>
                  </>
                )}
                {(user?.isBrand || user?.isExpert) && (
                  <>
                    <MenuItem
                      className="profile-selection__menu-item"
                      onClick={(event) =>
                        openFlaggedUrl(
                          event,
                          user?.hasPrToolbox,
                          'ai-press-release-generator',
                          '/ai/pr-toolbox'
                        )
                      }
                    >
                      <img
                        src={`${staticUrl}/images/svg/menu-robot.svg`}
                        className="profile-selection__menu-item-icon"
                      />
                      <div>PR Toolbox</div>
                      <div className="profile-selection__menu-item-notification">
                        {!user?.hasPrToolbox && (
                          <img
                            src={`${staticUrl}/images/svg/upgrade.svg`}
                            alt="Upgrade"
                          />
                        )}
                      </div>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  className="profile-selection__menu-item"
                  component={Link}
                  href={`https://presshook.firstpromoter.com?email=${user.email}&first_name=${user.firstName}&cust_id=${user.id}`}
                >
                  <img
                    src={`${staticUrl}/images/svg/menu-cash.svg`}
                    className="profile-selection__menu-item-icon"
                  />
                  <div>Referral Program</div>
                </MenuItem>
                {isOrgAdmin &&
                  (user?.isBrand || user?.isExpert) &&
                  featureFlags?.flagSubscriptionManagement && (
                    <MenuItem
                      className="profile-selection__menu-item"
                      component={Link}
                      href={`/subscriptions`}
                    >
                      <img
                        src={`${staticUrl}/images/svg/menu-card.svg`}
                        className="profile-selection__menu-item-icon"
                      />
                      <div>Subscriptions & Billing</div>
                    </MenuItem>
                  )}
                {isOrgAdmin && (user?.isBrand || user?.isExpert) && (
                  <MenuItem
                    className="profile-selection__menu-item"
                    component={Link}
                    onClick={(event) =>
                      openFlaggedUrl(
                        event,
                        user?.hasMultipleUsers,
                        'multiple-users',
                        '/accounts/users'
                      )
                    }
                  >
                    <img
                      src={`${staticUrl}/images/svg/menu-user.svg`}
                      className="profile-selection__menu-item-icon"
                    />
                    <div>Manage Users</div>
                    <div className="profile-selection__menu-item-notification">
                      {!user?.hasMultipleUsers && (
                        <img
                          src={`${staticUrl}/images/svg/upgrade.svg`}
                          alt="Upgrade"
                        />
                      )}
                    </div>
                  </MenuItem>
                )}
                <hr className="profile-selection__menu-divider" />
                <MenuItem
                  className="profile-selection__menu-item"
                  component={Link}
                  onClick={handleLogout}
                >
                  <img
                    src={`${staticUrl}/images/svg/menu-exit.svg`}
                    className="profile-selection__menu-item-icon"
                  />
                  <div>Sign Out</div>
                </MenuItem>
              </div>
            </div>
          </div>
        </Menu>
      </div>
      {showUpgradeModal && (
        <UpgradeSubscriptionModal
          selectedFeatureExternal={selectedFeature}
          setIsOpenExternal={setShowUpgradeModal}
          isOpenExternal={showUpgradeModal}
        />
      )}
    </>
  );
}

UserMenu.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMedia: PropTypes.bool.isRequired,
    isBrand: PropTypes.bool.isRequired,
    isExpert: PropTypes.bool.isRequired,
    isStaff: PropTypes.bool.isRequired,
    isApproved: PropTypes.bool.isRequired,
    pathwrightId: PropTypes.string, // This can be undefined, so it's not marked as required
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string, // This can also be undefined, so it's not marked as required
    hasPr101: PropTypes.bool,
    hasPrToolbox: PropTypes.bool,
  }).isRequired,
};

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import fromNow from '../utils/fromNow';

/**
 * Notification Item component
 * Each notification item on notifications list
 */

function NotificationItem({
  notification,
  message,
  readAt = null,
  markAsRead,
}) {
  const createdAt = new Date(notification.created_at);
  const onClickHandle = () => {
    markAsRead();
    if (notification.url) window.location.assign(notification.url);
  };

  const getProfileImage = () => {
    const defaultProfile = `${staticUrl}/images/svg/default_profile_thin.svg`;
    const PHLogo = `${staticUrl}/images/svg/company_logo.svg`;

    if (!notification.actor || notification.actor.is_staff) return PHLogo;

    return notification.actor.profile_image || defaultProfile;
  };

  return (
    <button
      type="button"
      id={notification.pk}
      data-testpk={notification.pk}
      data-testread={!!readAt}
      className="notifications__item"
      onClick={onClickHandle}
    >
      <div>
        <img
          className="user__avatar"
          src={getProfileImage()}
          alt="user avatar"
        />
      </div>
      <div className="notifications__item-content">
        <p className="notifications__item-headline">
          {notification.headline}
          <span
            className={cx('notifications__read-status', {
              'notifications__read-status--unread': !readAt,
            })}
          />
        </p>
        <p
          className="notifications__item-message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: message || notification.message,
          }}
        />
        <p className="notifications__item-created-at">{fromNow(createdAt)}</p>
      </div>
    </button>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    pk: PropTypes.number,
    // content_type: PropTypes.shape({
    //   app_label: PropTypes.string,
    //   id: PropTypes.number,
    //   model: PropTypes.string,
    // }),
    content_type: PropTypes.number,
    object_id: PropTypes.number,
    actor: PropTypes.shape({
      profile_image: PropTypes.string,
      is_staff: PropTypes.bool,
    }),
    action: PropTypes.string,
    headline: PropTypes.string,
    message: PropTypes.string,
    created_at: PropTypes.string,
    metadata: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
  message: PropTypes.string,
  readAt: PropTypes.string,
  markAsRead: PropTypes.func.isRequired,
};

export default NotificationItem;

import React from 'react';

export default function CollectionModal({
  title = '',
  bodyComponent = null,
  isOpen = false,
  setIsOpen = () => {},
  closeOnClickOutside = true,
  showCloseButton = true,
}) {
  const outsideModalRef = React.useRef(null);

  const handleClick = (event) => {
    if (
      closeOnClickOutside &&
      outsideModalRef.current &&
      outsideModalRef.current == event.target
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    // Add event listener only when the modal is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="collections-modal" data-testid="collectionsModal">
          <div className="collections-modal__overlay" ref={outsideModalRef}>
            <div className="collections-modal__modal">
              <div className="collections-modal__header">
                {showCloseButton && (
                  <button
                    className="collections-modal__close-btn"
                    onClick={() => setIsOpen(false)}
                  ></button>
                )}
                {title !== '' && (
                  <div
                    className={
                      showCloseButton
                        ? 'collections-modal__title'
                        : 'collections-modal__title collections-modal__title--without-close-btn'
                    }
                  >
                    {title}
                  </div>
                )}
              </div>
              <div className="collections-modal__body">{bodyComponent}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React from 'react';
import TagSelector from '../../../../../../../config/static/js/components/jsx/tags/tagSelector';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../../../config/static/js/utils/logError';

function AddExpertForm({ expert, setSection, setExpert, initialSlotPk }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(null);
  const [industries, setIndustries] = React.useState([]);
  const [industriesError, setIndustriesError] = React.useState(null);
  const [freeSlots, setFreeSlots] = React.useState([]);
  const [slot, setSlot] = React.useState(initialSlotPk);
  const [slotError, setSlotError] = React.useState(null);
  const [generalError, setGeneralError] = React.useState(null);

  /**
   * Set expert values if they're already filled before
   */
  React.useEffect(() => {
    if (expert) {
      setName(expert.name);
      setIndustries(expert.industries);
    }
  }, [expert]);

  /**
   * Retrieves slots for the current organization
   */
  React.useEffect(() => {
    setIsLoading(true);
    fetch(`/api/subscription-slots/?free=expert`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
        'Cache-Control': 'no-cache',
      },
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setFreeSlots(data);
        } else {
          setFreeSlots([]);
        }
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  /**
   * Selects the first slot if there are any
   */
  React.useEffect(() => {
    if (freeSlots.length > 0 && !slot) {
      setSlot(freeSlots[0].id);
    }
  }, [freeSlots]);

  /**
   * Handles input validation and value setting
   */
  const handleInputOnChange = (event, setValue, setError) => {
    const value = event.target.value;
    if (value.length < 1) {
      setError('Please enter a value');
    } else {
      setError(null);
    }
    setValue(value);
  };

  /**
   * Handles the brand creation data
   */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setGeneralError(null);
    setSlotError(null);

    if (name.length < 1) {
      setNameError('Please enter an expert name');
    } else {
      setNameError(null);
    }

    if (industries.length < 1) {
      setIndustriesError('Please select at least one industry');
    } else {
      setIndustriesError(null);
    }

    if (name.length < 1 || industries.length < 1) {
      setIsLoading(false);
      return;
    }

    const subscription_slot = slot == 'Select a subscription...' ? null : slot;

    const data = {
      name: name,
      industries: industries,
      subscription_slot: subscription_slot,
    };

    if (subscription_slot) {
      // Creates the expert immediately, since a slot was selected
      fetch('/api/experts/', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => {
          if (!res.ok) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .then((data) => {
          if (data.slots.length > 0) {
            // Redirects to Brand Editor, since a slot exists for this new brand
            window.location.href = `/experts/profile/edit?expert_pk=${data.pk}`;
          } else {
            setExpert(data);
            setSection('PlanSelectionList');
          }
        })
        .catch((data) => {
          // General error handling
          if (data.status === 500) {
            setGeneralError(`There was an internal error creating your expert.
                             Try again. If the problem persists, please contact support.`);
            logError(data);
          } else {
            return data.json();
          }
        })
        .then((error_data) => {
          // Handleable errors
          if (error_data) {
            let handled = false;
            if (error_data.title) {
              setNameError(error_data.title[0]);
              handled = true;
            }
            if (error_data.industries) {
              setIndustriesError(error_data.industries[0]);
              handled = true;
            }
            if (error_data.subscription_slot) {
              setSlotError(error_data.subscription_slot);
              handled = true;
            }
            if (!handled) {
              setGeneralError(`There was an internal error creating your expert.
                               Try again. If the problem persists, please contact support.`);
              logError(error_data);
            }
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      // just validates data and sets the new expert data
      fetch('/api/experts/?validate_only=true', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => {
          if (!res.ok) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .then(() => {
          // setting the Expert state with the new expert data
          // and with pk = '' so we can check fro expert.pk == ''
          // to know if the expert still needs to be created
          setExpert({ ...data, pk: '' });
          setSection('PlanSelectionList');
        })
        .catch((data) => {
          // General error handling
          if (data.status === 500) {
            setGeneralError(`There was an internal error creating your expert.
                             Try again. If the problem persists, please contact support.`);
            logError(data);
          } else {
            return data.json();
          }
        })
        .then((error_data) => {
          // Handleable errors
          if (error_data) {
            let handled = false;
            if (error_data.title) {
              setNameError(error_data.title[0]);
              handled = true;
            }
            if (error_data.industries) {
              setIndustriesError(error_data.industries[0]);
              handled = true;
            }
            if (error_data.detail) {
              if (
                error_data.detail ===
                'You do not have permission to perform this action.'
              ) {
                setGeneralError(
                  'You need to be an organization admin to perform this action.'
                );
              } else {
                setGeneralError(error_data.detail);
              }
              handled = true;
            }
            if (!handled) {
              setGeneralError(`There was an internal error creating your expert.
                               Try again. If the problem persists, please contact support.`);
              logError(error_data);
            }
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <h2 className="add-brand-modal__title">Create a New Expert</h2>
      {generalError && (
        <p className="add-brand-modal__field-error add-brand-modal__field-error--general">
          {generalError}
        </p>
      )}
      <form onSubmit={handleFormSubmit}>
        <div className="add-brand-modal__field-container">
          <label htmlFor="brandName" className="add-brand-modal__field-label">
            Expert Name:{' '}
            <span className="add-brand-modal__field-label--required">*</span>
          </label>
          <input
            type="text"
            id="expertName"
            name="expertName"
            value={name}
            className={
              nameError
                ? 'add-brand-modal__field-input add-brand-modal__field-input--error'
                : 'add-brand-modal__field-input'
            }
            onChange={(e) => handleInputOnChange(e, setName, setNameError)}
          />
          {nameError && (
            <span className="add-brand-modal__field-error">{nameError}</span>
          )}
        </div>
        <div className="add-brand-modal__field-container">
          <label htmlFor="industries" className="add-brand-modal__field-label">
            Industries:{' '}
            <span className="add-brand-modal__field-label--required">*</span>
          </label>
          <TagSelector
            tagType="industries"
            tags={industries}
            onChange={setIndustries}
            placeHolder={'Select industries...'}
          />
          {industriesError && (
            <span className="add-brand-modal__field-error">
              {industriesError}
            </span>
          )}
        </div>
        {freeSlots.length > 0 && (
          <div className="add-brand-modal__field-container">
            <label
              htmlFor="subscription_slot"
              className="add-brand-modal__field-label"
            >
              Use available subscription:
            </label>
            <select
              id="subscription_slot"
              name="subscription_slot"
              className="add-brand-modal__field-input"
              value={slot}
              onChange={(e) => setSlot(e.target.value)}
            >
              <option value={null}>Select a subscription...</option>
              {freeSlots.map((subSlot, key) => (
                <option value={subSlot.id} key={key}>
                  {subSlot.product_name}{' '}
                  {subSlot.available_quantity > 1 &&
                    `x${subSlot.available_quantity}`}
                </option>
              ))}
            </select>
            {slotError && (
              <span className="add-brand-modal__field-error">{slotError}</span>
            )}
          </div>
        )}
        <button
          type="submit"
          className={
            isLoading
              ? 'add-brand-modal__submit-btn add-brand-modal__submit-btn--disabled'
              : 'add-brand-modal__submit-btn'
          }
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Next'}
        </button>
      </form>
    </>
  );
}

export default AddExpertForm;

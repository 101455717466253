import React from 'react';
import ReactDOM from 'react-dom/client';
import ProfileSelection from './ProfileSelector';
/* This file is for injecting the Profile Selection component in the navbar.
 */
document.addEventListener('DOMContentLoaded', () => {
  const reactTarget = document.querySelector('#js-profile-selector');

  if (reactTarget) {
    const root = ReactDOM.createRoot(reactTarget);
    root.render(
      <ProfileSelection
        hasSampleRequests={reactTarget.dataset.userHasSampleRequests === 'True'}
        userOrgRole={reactTarget.dataset.userOrgRole}
      />
    );
  }
});

import { getImageURL } from '../../../utils/wagtailImageUtils';
import BrandContactRequestBrandsAssociatedComponent from '../../ConversationDetailView/DetailsComponents/BrandContactRequestBrandsAssociatedComponent';

import BrandContactRequestBrandTagsComponent from '../../ConversationDetailView/DetailsComponents/BrandContactRequestBrandTagsComponent';

import BrandContactRequestDetailsComponentBrand from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentBrand';

import BrandContactRequestDetailsComponentMedia from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentMedia';

import BrandContactRequestDetailsComponentRequestedByBrand from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentRequestedByBrand';

import ContactRequestMediaUserDetailsComponent from '../../ConversationDetailView/DetailsComponents/ContactRequestMediaUserDetailsComponent';
import BrandRelatedConversation from './BrandRelatedConversation';

/**
 * Methd for determining detail text for a Brand Contact Request conversation
 * @param {*} contentObject contact request
 * @param {*} userIsRequester bool determining if the current chat user is the one requesting access or not
 * @returns string
 */
function determineHeaderDetailText(contentObject, userIsRequester) {
  // If media user show name of brand
  if (userIsRequester) {
    return '';
  }

  return `Subject: ${contentObject.brand.title}`;
}

/**
 * Class that implements custom header detail text and icon determination for Brand Contact Request conversations.
 */
export default class BrandContactRequestConversation extends BrandRelatedConversation {
  constructor(conversationObject) {
    super(conversationObject);

    const userIsRequester =
      this.getIdentity() &&
      this.getContentObject().requested_by ===
        this.getIdentity().getUser().getPk();

    this.typeText = 'Contact Request';

    this.headerDetailText = determineHeaderDetailText(
      this.getContentObject(),
      userIsRequester
    );

    const brandIcon =
      this.getContentObject().brand.logo &&
      getImageURL(this.getContentObject().brand.logo);

    this.icon =
      userIsRequester && brandIcon
        ? brandIcon
        : this.getOtherMembersExcludingStaff()[0].getUser().getProfileImage();

    if (!userIsRequester) {
      // Check if the request was made by a brand rep
      if (this.getContentObject().requested_by_brand) {
        // Check if there is a requested_by_brand_object
        // This denotes that the requester only has one brand
        if (this.getContentObject().requested_by_brand_object) {
          this.relatedLink = {
            title: 'View Brand',
            href: `/brands/${
              this.getContentObject().requested_by_brand_object.slug
            }/`,
          };
          this.detailSections = {
            // Conversation Details
            Details: BrandContactRequestDetailsComponentBrand,
            // HQ and Website
            'Brand Details':
              BrandContactRequestDetailsComponentRequestedByBrand,
            // Brand logo + name
            'Associated Brands': BrandContactRequestBrandsAssociatedComponent,
            // Associated Brand Tags
            'Brand Tags': BrandContactRequestBrandTagsComponent,
          };
        } else {
          // If there is more than one brand, show them in a list
          // as Associated Brands
          this.detailSections = {
            Details: BrandContactRequestDetailsComponentBrand,
            'Associated Brands': BrandContactRequestBrandsAssociatedComponent,
          };
        }
      } else {
        // If the requester is Media, show the media profile
        this.detailSections = {
          Details: BrandContactRequestDetailsComponentBrand,
          'Media Profile': ContactRequestMediaUserDetailsComponent,
        };
      }
    } else {
      // Otherwise show the brand to media as normal
      this.relatedLink = {
        title: 'View Brand',
        href: `/brands/${this.getContentObject().brand.slug}/`,
      };
      this.detailSections = {
        Details: BrandContactRequestDetailsComponentBrand,
        'Brand Details': BrandContactRequestDetailsComponentMedia,
      };
    }
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns string
   */
  getCustomFlavorTextForMember(member) {
    if (this.getContentObject().requested_by !== member.getUser().getPk()) {
      return `Brand Rep @ ${this.getContentObject().brand.title}`;
    }
    if (this.getContentObject().requested_by_brand) {
      return member.getUser().getBrandRepFlavorText();
    }
    return 'Media Member';
  }

  /**
   * Returns the role text to be shown underneath a users name in the messaging area
   * @param {*} member
   * @returns string
   */
  getCustomRoleTextForMember(member) {
    return this.getContentObject().requested_by !== member.getUser().getPk()
      ? this.getContentObject().brand.title
      : '';
  }
}

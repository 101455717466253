/**
 * Selecting a section
 * @param {String} target
 */
const selectSection = (target) => {
  const sections = document.querySelectorAll('.how-it-works__section');
  const dynamicStudies = document.querySelectorAll('.toggle-container');

  sections.forEach((section) => {
    if (section.dataset.targetSection === target) {
      section.classList.add('how-it-works__section--selected');
    } else {
      section.classList.remove('how-it-works__section--selected');
      section.querySelector('video').pause();
    }
  });

  dynamicStudies.forEach((study) => {
    if (study.dataset.targetSection !== target) {
      study.classList.add('toggle-container--hidden');
    } else {
      study.classList.remove('toggle-container--hidden');
    }
  });
};

/**
 * Creating menu items marking the relative selected one
 * @param {String} selectedOption
 * @returns {Array.<Element>} List of created HTML elements
 */
const createMenuItems = (selectedOption) => {
  const sections = document.querySelectorAll('.how-it-works__section');
  const menuOptions = [...sections].map(
    (section) => section.dataset.targetSection
  );
  const menuItems = [];

  menuOptions.forEach((option) => {
    const menuItem = document.createElement('div');
    const menuItemLink = document.createElement('a');

    menuItem.classList.add('how-it-works__menu-option');
    menuItemLink.innerHTML = `${option}`;
    menuItemLink.href = '#'; // Links without href don't trigger the cursor appearance change.
    menuItemLink.dataset.target = option;
    menuItemLink.classList.add('how-it-works__menu-option-link');

    if (selectedOption === option) {
      menuItemLink.classList.add('how-it-works__menu-option-link--selected');
    }

    // Biding to an event
    menuItemLink.addEventListener('click', (e) => {
      const { target } = e.target.dataset;
      e.preventDefault();
      selectSection(target);
    });

    menuItem.appendChild(menuItemLink);
    menuItems.push(menuItem);
  });

  return menuItems;
};

/**
 * Appending menu items to a section menu
 * @param {Element} section
 */
const appendMenuItems = (section) => {
  const menu = section.querySelector('.how-it-works__section-menu');
  const { targetSection } = section.dataset;
  const items = createMenuItems(targetSection);
  items.forEach((item) => menu.appendChild(item));
};

// Apply tabs behavior
document.addEventListener('DOMContentLoaded', () => {
  const howitworks = document.querySelector('.how-it-works__sections');

  if (howitworks) {
    const sections = document.querySelectorAll('.how-it-works__section');

    // Configuring each section
    sections.forEach((section) => {
      appendMenuItems(section);

      if (section.dataset.selected !== undefined)
        section.classList.add('how-it-works__section--selected');
    });

    if (
      document.querySelectorAll('.how-it-works__section--selected').length === 0
    ) {
      sections[0].classList.add('how-it-works__section--selected');
    }
  }
});

import React from 'react';
import { createRoot } from 'react-dom/client';
import StandaloneTagSelector from './jsx/tags/standaloneTagSelector';

class TagSelectorInjector {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    let existingValue = this.element.getAttribute('data-existing-value');
    // if existingValue is a weird string remove the brackets and quotes and parse it to an array

    if (existingValue && existingValue.match(/[\[\]']+/g, '')) {
      existingValue = existingValue.replace(/[\[\]']+/g, '').split(',');
    }
    // if existingValue is an array with an empty string set it to an empty array
    if (
      existingValue &&
      existingValue.length === 1 &&
      existingValue[0] === ''
    ) {
      existingValue = [];
    }
    // convert existingValue array to a string
    if (existingValue && Array.isArray(existingValue)) {
      existingValue = existingValue.join(',');
    }

    const tagType = this.element.getAttribute('data-tag-type');
    const placeholder = this.element.getAttribute('data-placeholder');
    const formInputName = this.element.getAttribute('data-form-input-name');
    const filterFor = this.element.getAttribute('data-filter-for');
    const withSuggestions = this.element.getAttribute('data-with-suggestions');
    const submitOnChange =
      this.element.getAttribute('data-submit-on-change') === 'True';

    const root = createRoot(this.element);

    root.render(
      <StandaloneTagSelector
        tagType={tagType}
        filterFor={filterFor}
        placeHolder={placeholder}
        formInputName={formInputName}
        containerStyle={{ width: '100%' }}
        existingValue={existingValue}
        withSuggestions={withSuggestions}
        submitWhenChanged={submitOnChange}
      />,
      this.element
    );
  }
}

document.querySelectorAll('.js-tag-selector').forEach((element) => {
  new TagSelectorInjector(element);
});

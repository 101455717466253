// adapted from https://usehooks-ts.com/react-hook/use-local-storage
// usage example:
// const [isDarkTheme, setDarkTheme] = useLocalStorage('darkTheme', true)

import { useCallback, useEffect, useState } from 'react';
import logError from './logError';

// A wrapper for "JSON.parse()" to support "undefined" value
function parseJSON(value) {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value);
  } catch {
    logError(`parsing error on ${value}`);
    return undefined;
  }
}

/**
 * A custom hook that allows you to store and retrieve data from local storage.
 * @param {string} key - The key to use for storing and retrieving data from local storage.
 * @param {any} initialValue - The initial value to use if no value is found in local storage.
 * @returns {[any, function]} - An array containing the stored value and a function to update the stored value.
 */
function useLocalStorage(key, initialValue) {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback(() => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? parseJSON(item) : initialValue;
    } catch (error) {
      logError(`Error reading localStorage key “${key}”: ${error}`);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      logError(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
      return;
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      logError(`Error setting localStorage key “${key}”: ${error}`);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  const handleStorageChange = useCallback(
    (event) => {
      if (event.key && event.key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  // this only works for other documents, not the current one
  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEffect(() => {
    window.addEventListener('local-storage', handleStorageChange);
    return () => {
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  return [storedValue, setValue];
}

export default useLocalStorage;
